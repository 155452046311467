<template>
  <section
    class="pdf-content"
  >
    <section class="report-info">
      <section class="user-details">
        <service-number-detail-print
          :sn-data="serviceNumber"
        />
      </section>
    </section>
    <section
      class="chart-container"
      >
      <h4 class="time">
        12 months Total ($)
      </h4>
    <service-number-bill-total-line-chart
      :sn-data="serviceNumber"
    />
    </section>
    <div class="html2pdf__page-break">
    </div>
    <p style="margin: 10px 20px 10px 20px">
      <br />
    </p>
    <section
      class="chart-container"
    >
      <h4 class="time">
        National Data Usage (Mb)
      </h4>
      <service-number-data-usage-line-chart
        :sn-data="serviceNumber"
      />
    </section>
    <section
      class="chart-container"
    >
      <h4 class="time">
        Minutes of Use
      </h4>
      <service-number-minutes-of-use-line-chart
        :sn-data="serviceNumber"
      />
    </section>
    <section
      class="chart-container"
    >
      <h4 class="time">
        Overseas Data Usage (Mb)
      </h4>
      <service-number-data-overseas-usage-line-chart
        :sn-data="serviceNumber"
      />
    </section>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ServiceNumberMinutesOfUseLineChart from '@/views/blitz/components/apex/ServiceNumberMinutesOfUseLineChartPrint'
  import ServiceNumberDataUsageLineChart from '@/views/blitz/components/apex/ServiceNumberDataUsageLineChartPrint'
  import ServiceNumberBillTotalLineChart from '@/views/blitz/components/apex/ServiceNumberBillTotalLineChartPrint'
  import ServiceNumberDetailPrint from '@/views/blitz/components/ServiceNumberDetailPrint'
  import ServiceNumberDataOverseasUsageLineChart from '@/views/blitz/components/apex/ServiceNumberDataOverseasUsageLineChartPrint'

  export default {
    name: 'ServiceNumberPrintContent',
    components: {
      ServiceNumberBillTotalLineChart,
      ServiceNumberMinutesOfUseLineChart,
      ServiceNumberDataUsageLineChart,
      ServiceNumberDetailPrint,
      ServiceNumberDataOverseasUsageLineChart,
    },
    props: {
      snData: {
        type: String,
        default: '',
      },
    },
    computed: {
      serviceNumber () {
        return this.snDataLocal
      },
    },
    data () {
      return {
        snDataLocal: null,
        print: false,
      }
    },
    watch: {
      snData: {
        handler () {
          console.log('ServiceNumberPrint snData changed to ' + this.snData)
          this.snDataLocal = this.snData
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

.pdf-content {
  width: 100%;
  background: #fff;

  .report-info {
    display: flex;
    padding: 20px;
    padding-bottom: 0px;

    .image-container {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin-right: 25px;
      border: 1px solid #dadada;

      .user-image {
        height: 100%;
        min-width: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
    .user-details {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .user-name {
        margin-bottom: 10px;
        flex-shrink: 0;
      }
      .detail-container {
        font-size: 13px;
        flex-shrink: 0;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .detail-label {
          font-weight: 600;
          margin-right: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .chart-container {
    width: 95%;
    height: 265px;
    padding: 5px;
    pointer-events: none;

    &:not(:last-child) {
       margin-bottom: 5px;
     }
  }
}
</style>
