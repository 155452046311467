<template>
  <section
    style="margin-left:20px;width: 700px;vertical-align:top;"
  >
    <v-card
      class="pa-3"
      style="border: none;font-family: Roboto;"
    >
      <v-container>
       <table
        class="table"
        width="100%"
      >
        <tr>
          <td>
                <div class="Service_Title">{{ serviceNumberTitle }}</div>
          </td>
        </tr>
      </table>
      <table
        class="table"
        width="100%"
      >
        <tr>
          <td
            width="350px"
          >
            <div>
              <div class="label">
                Account
              </div>
              <div class="text-body">{{ accountNumber }}</div>
            </div>
          </td>
          <td>
            <div>
              <div class="label">
                User Name
              </div>
              <div class="text-body">{{ userName }}</div>
            </div>
          </td>
        </tr>
      </table>
      <table
        class="table"
        width="100%"
      >
        <tr>
          <td>
            <div>
              <div class="label">
                Device
              </div>
              <div class="detail-highlight">{{ deviceName }}</div>
              <table width="100%">
                <tr>
                  <td class="detail-label">
                    Category
                  </td>
                  <td class="text-body">{{ deviceType }}</td>
                </tr>
                <tr>
                  <td class="detail-label">
                    Network
                  </td>
                  <td class="text-body">{{ deviceCapability }}</td>
                </tr>
                <tr>
                  <td class="detail-label">
                    IMEI
                  </td>
                  <td class="text-body">{{ imei }}</td>
                </tr>
              </table>
            </div>
          </td>
          <td>
            <div>
              <div class="label">
                Sim Details
              </div>
              <div class="detail-highlight">{{ sim }}</div>
              <table width="100%">
                <tr>
                  <td class="detail-label">
                    Sim Network
                  </td>
                  <td>{{ simNetwork }}</td>
                </tr>
                <tr>
                  <td class="detail-label">
                    Activation
                  </td>
                  <td>{{ serviceActivationDate }}</td>
                </tr>
                <tr>
                  <td class="detail-label">.&nbsp;</td>
                  <td class="text-body">&nbsp;</td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
      <table
        class="table"
        width="100%"
        style="margin-top: 20px"
      >
        <tr>
          <td colspan="2">
            <div class="label">Associated Plans</div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="detail-highlight">{{ flexiPlanName }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ flexiPlan }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Start Date
                </td>
                <td class="text-body">
                  {{ flexiStart }}
                </td>
              </tr>
            </table>
          </td>
          <td v-if="dataPlan1 > ''">
            <div class="detail-highlight">{{ dataPlan1Description }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ dataPlan1 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ dataPlan1MonthlyCommitment }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td v-if="dataPlan2 > ''">
            <div class="detail-highlight">{{ dataPlan2Description }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ dataPlan2 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ dataPlan2MonthlyCommitment }}
                </td>
              </tr>
            </table>
          </td>
          <td v-if="shareableDataPlan > ''">
            <div class="detail-highlight">{{ shareablePlanName }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ shareableDataPlan }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ shareablePlanFee }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td v-if="shareableDataPlan2 > ''">
            <div class="detail-highlight">{{ shareablePlanName2 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ shareableDataPlan2 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ shareablePlanFee2 }}
                </td>
              </tr>
            </table>
          </td>
          <td v-if="shareableBonusDataPlan1 > ''">
            <div class="detail-highlight">{{ shareableBonusPlanName1 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ shareableBonusDataPlan1 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ shareableBonusPlanFee1 }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td v-if="shareableBonusDataPlan2 > ''">
            <div class="detail-highlight">{{ shareableBonusPlanName2 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ shareableBonusDataPlan2 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ shareableBonusPlanFee2 }}
                </td>
              </tr>
            </table>
          </td>
          <td v-if="nonShareableDataPlan1 > ''">
            <div class="detail-highlight">{{ nonShareablePlanName1 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ nonShareableDataPlan1 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ nonShareablePlanFee1 }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td v-if="nonShareableDataPlan2 > ''">
            <div class="detail-highlight">{{ nonShareablePlanName2 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ nonShareableDataPlan2 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ nonShareablePlanFee2 }}
                </td>
              </tr>
            </table>
          </td>
          <td v-if="nonShareableBonusDataPlan1 > ''">
            <div class="detail-highlight">{{ nonShareableBonusPlanName1 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ nonShareableBonusDataPlan1 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ nonShareableBonusPlanFee1 }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td v-if="nonShareableBonusDataPlan2 > ''">
            <div class="detail-highlight">{{ nonShareableBonusPlanName2 }}</div>
            <table width="100%">
              <tr>
                <td class="detail-label">
                  Code
                </td>
                <td class="text-body">
                  {{ nonShareableBonusDataPlan2 }}
                </td>
              </tr>
              <tr>
                <td class="detail-label">
                  Monthly Fee:
                </td>
                <td class="text-body">
                  ${{ nonShareableBonusPlanFee2 }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table
        class="table"
        width="100%"
        style="margin-top: 20px"
      >
        <tr>
          <td colspan="2">
            <div class="label">
              Configured Options
            </div>
          </td>
        </tr>
      </table>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
          <v-chip
            v-for="tag in configuredOptionsList"
            :key="tag.name"
            :color="tag.enabled? '#0A9A8D': '#D9D9D9'"
            :text-color="tag.enabled? '#fcffff': '#353535'"
            style="margin-top: 5px; margin-left: 5px"
          >
            <v-avatar left>
              <v-icon v-if="tag.enabled">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon v-if="!tag.enabled">
                mdi-checkbox-blank-circle-outline
              </v-icon>
            </v-avatar>
            <div style="width:110px">
              {{ tag.label }}
            </div>
          </v-chip>
        </div>
      </v-container>
    </v-card>
  </section>
</template>
<script>
  import moment from 'moment'
  import { findSn } from '@/api/PouchDaoReport'
  import Utils from '@/api/util'
  import { mapGetters } from 'vuex'
  import { configuredOptions } from '@/api/ConfiguredOptionReport'

  export default {
    name: 'ServiceNumberDetailPrint',
    props: {
      snData: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        serviceNumberTitle: '',
        serviceNumberRecord: null,
        serviceActivationDate: '2019',
        configuredOptionsList: [],
        accountNumber: '',
        userName: '',
        sim: '',
        simNetwork: '',
        deviceName: '',
        deviceType: '',
        deviceCapability: '',
        deviceCategory: '',
        imei: '',
        flexiPlan: '',
        flexiPlanName: '',
        flexiStart: '',
        flexiEnd: '',
        dataPlan1: '',
        dataPlan1Description: '',
        dataPlan1MonthlyCommitment: '',
        dataPlan2: '',
        dataPlan2Description: '',
        dataPlan2MonthlyCommitment: '',
        shareableDataPlan: '',
        shareablePlanName: '',
        shareablePlanFee: '',
        shareableDataPlan2: '',
        shareablePlanName2: '',
        shareablePlanFee2: '',
        shareableBonusPlanName1: '',
        shareableBonusDataPlan1: '',
        shareableBonusPlanFee1: '',
        shareableBonusPlanName2: '',
        shareableBonusDataPlan2: '',
        shareableBonusPlanFee2: '',
        nonShareablePlanName1: '',
        nonShareableDataPlan1: '',
        nonShareablePlanFee1: '',
        nonShareablePlanName2: '',
        nonShareableDataPlan2: '',
        nonShareablePlanFee2: '',
        nonShareableBonusPlanName1: '',
        nonShareableBonusDataPlan1: '',
        nonShareableBonusPlanFee1: '',
        nonShareableBonusPlanName2: '',
        nonShareableBonusDataPlan2: '',
        nonShareableBonusPlanFee2: '',
        internationalRoaming: '',
        serviceContractStart: '',
        serviceContractEnd: '',
        mro1Contract: '',
        mro1ContractStartDate: '',
        mro1ContractEndDate: '',
        mro1ContractMonthlyCommitment: '',
        barOutgoingVoice: '',
        barOutgoingSMS: '',
        barIncomingSMS: '',
        barPremiumSSMS: '',
        spendLimitActive: '',
        mmsAccess: '',
        videoCallingAccess: '',
        telstraCorp: '',
        telstraXtranet: '',
        telstraDataPack: '',
        telstraPCPack: '',
        barNextGWAP: '',
        barNextGMMS: '',
        barNextGInternetAccess: '',
        barVideoCalls: '',
        oneNumberFlag: '',
        barRoaming: '',
        barIDD: '',
        usernamePrintOnBill: '',
      }
    },
    computed: {
      ...mapGetters('serviceNumber', {
        currentSn: 'currentSn',
      }),
    },
    watch: {
      currentSn: {
        handler () {
          console.log('ServiceNumberDetailPrint currentSn changed to ' + this.currentSn)
          this.loadDetail(this.currentSn)
        },
      },
      snData: {
        handler () {
          console.log('ServiceNumberDetailPrint snData changed to ' + this.snData)
          this.loadDetail(this.snData)
        },
      },
      serviceNumberRecord: 'updateConfiguredOptionList'
    },
    mounted () {
      console.log('ServiceNumberDetailPrint mounted snData is ' + this.snData)
    // this.loadDetail(this.snData)
    },
    methods: {
      updateConfiguredOptionList () {
        console.log('ServiceNumberDetailPrint updateConfiguredOptionList configuredOptions changed ', this.serviceNumberRecord)
        let optionsList = []
        configuredOptions.forEach(option => {
          const record = {...option}
          record.enabled = this.serviceNumberRecord[option.name] === 'YES' || this.serviceNumberRecord[option.name] === 'Y'
          optionsList.push(record)
        })
        this.configuredOptionsList = optionsList
      },
      loadDetail (snData) {
        findSn(snData).then(result => {
          console.log('ServiceNumberDetailPrint loadDetail sn detail loaded for sn ' + snData + ' result is', result)
          this.serviceNumberRecord = result
          this.serviceNumberTitle = snData
          this.serviceActivationDate = moment(result.snActivationDate).format('DD MMM YYYY')
          this.accountNumber = result.acct
          this.userName = result.userName
          this.imei = result.imei
          this.sim = result.sim
          this.simNetwork = result.simNetwork
          this.deviceName = result.deviceName
          this.deviceType = result.deviceType
          this.deviceCapability = result.deviceCapability
          this.deviceCategory = result.deviceCategory
          this.flexiPlan = result.flexiPlan
          this.flexiPlanName = result.flexiPlanName
          this.flexiStart = moment(result.flexiStart).format('DD MMM YYYY')
          this.flexiEnd = moment(result.flexiEnd).format('DD MMM YYYY')

          this.internationalRoaming = result.internationalRoaming
          this.serviceContractStart = result.serviceContractStart
          this.serviceContractEnd = result.serviceContractEnd
          this.mro1Contract = result.mro1Contract
          this.mro1ContractStartDate = result.mro1ContractStartDate
          this.mro1ContractEndDate = result.mro1ContractEndDate
          this.mro1ContractMonthlyCommitment = result.mro1ContractMonthlyCommitment
          this.dataPlan1 = result.dataPlan1
          this.dataPlan1Description = result.dataPlan1Description
          this.dataPlan1MonthlyCommitment = result.dataPlan1MonthlyCommitment
          this.dataPlan2 = result.dataPlan2
          this.dataPlan2Description = result.dataPlan2Description
          this.dataPlan2MonthlyCommitment = result.dataPlan2MonthlyCommitment
          this.shareableDataPlan = result.shareableDataPlan
          this.shareablePlanName = result.shareablePlanName
          this.shareablePlanFee = result.shareablePlanFee
          this.shareableDataPlan2 = result.shareableDataPlan2
          this.shareablePlanName2 = result.shareablePlanName2
          this.shareablePlanFee2 = result.shareablePlanFee2
          this.shareableBonusPlanName1 = result.shareableBonusPlanName1
          this.shareableBonusDataPlan1 = result.shareableBonusDataPlan1
          this.shareableBonusPlanFee1 = result.shareableBonusPlanFee1
          this.shareableBonusPlanName2 = result.shareableBonusPlanName2
          this.shareableBonusDataPlan2 = result.shareableBonusDataPlan2
          this.shareableBonusPlanFee2 = result.shareableBonusPlanFee2
          this.nonShareablePlanName1 = result.nonShareablePlanName1
          this.nonShareableDataPlan1 = result.nonShareableDataPlan1
          this.nonShareablePlanFee1 = result.nonShareablePlanFee1
          this.nonShareablePlanName2 = result.nonShareablePlanName2
          this.nonShareableDataPlan2 = result.nonShareableDataPlan2
          this.nonShareablePlanFee2 = result.nonShareablePlanFee2
          this.nonShareableBonusPlanName1 = result.nonShareableBonusPlanName1
          this.nonShareableBonusDataPlan1 = result.nonShareableBonusDataPlan1
          this.nonShareableBonusPlanFee1 = result.nonShareableBonusPlanFee1
          this.nonShareableBonusPlanName2 = result.nonShareableBonusPlanName2
          this.nonShareableBonusDataPlan2 = result.nonShareableBonusDataPlan2
          this.nonShareableBonusPlanFee2 = result.nonShareableBonusPlanFee2
          this.barOutgoingVoice = result.barOutgoingVoice
          this.barOutgoingSMS = result.barOutgoingSMS
          this.barIncomingSMS = result.barIncomingSMS
          this.barPremiumSSMS = result.barPremiumSSMS
          this.spendLimitActive = result.spendLimitActive
          this.mmsAccess = result.mmsAccess
          this.videoCallingAccess = result.videoCallingAccess
          this.telstraCorp = result.telstraCorp
          this.telstraXtranet = result.telstraXtranet
          this.telstraDataPack = result.telstraDataPack
          this.telstraPCPack = result.telstraPCPack
          this.barNextGWAP = result.barNextGWAP
          this.barNextGMMS = result.barNextGMMS
          this.barNextGInternetAccess = result.barNextGInternetAccess
          this.barVideoCalls = result.barVideoCalls

          this.oneNumberFlag = result.oneNumberFlag
          this.barRoaming = result.barRoaming
          this.barIDD = result.barIDD

          this.usernamePrintOnBill = result.usernamePrintOnBill

        //                this.flex
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.label {
  font-size: 18px !important;
  padding-bottom: 6px;
  color: rgba(233, 30, 99, 1) !important;
  font-weight: 400;
}
.detail-highlight {
  font-size: 16px !important;
  padding-bottom: 2px;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400;
}
.detail-label {
  font-size: 14px !important;
  color: #666565;
  padding-bottom: 2px;
  font-weight: 400;
}
.text-body {
  font-size: 14px !important;
  padding-bottom: 2px;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400;
}
.micandi-container {
  line-height: 1.2rem;
  font-size: 12px;
}

</style>
