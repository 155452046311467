<template>
  <v-container
    id="service-number-page"
    tag="section"
    fluid
    class="pt-0 mt-0"
  >
    <v-row
      class="pt-0"
      align="start"
      justify="center"
    >
      <v-col
        cols="12"
        lg="6"
        md="12"
        class="pt-0 mt-0"
      >
        <service-detail
          :sn-data="serviceNumber"
        />
      </v-col>

      <v-col
        cols="12"
        lg="6"
        md="12"
        class="pt-0 mt-0"
      >
        <v-container>
          <service-number-bill-total-line-chart
            :sn-data="serviceNumber"
          />
          <service-number-data-national-usage-line-chart
            :sn-data="serviceNumber"
          />
          <service-number-minutes-of-use-line-chart
            :sn-data="serviceNumber"
          />
          <service-number-data-overseas-usage-line-chart
            :sn-data="serviceNumber"
          />
        </v-container>
      </v-col>
    </v-row>
    <service-number-print :sn-data="serviceNumber"></service-number-print>
  </v-container>
</template>

<script>

  import store from '@/store'
  // import UsageRadar from '@/views/blitz/components/apex/ServiceNumberUsageRadar'
  import ServiceNumberMinutesOfUseLineChart from '@/views/blitz/components/apex/ServiceNumberMinutesOfUseLineChart'
  import ServiceNumberDataNationalUsageLineChart from '@/views/blitz/components/apex/ServiceNumberDataNationalUsageLineChart'
  import ServiceNumberDataOverseasUsageLineChart from '@/views/blitz/components/apex/ServiceNumberDataOverseasUsageLineChart'
  // import ServiceNumberDataUsageLineChart from '@/views/blitz/components/apex/ServiceNumberDataUsageLineChart'
  import ServiceNumberBillTotalLineChart from '@/views/blitz/components/apex/ServiceNumberBillTotalLineChart'
  import ServiceDetail from '@/views/blitz/components/ServiceNumberDetail'
  import ServiceNumberPrint from '@/views/blitz/components/ServiceNumberPrint'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ServiceNumberPage',
    props: {
      snData: {
        type: String,
        default: null,
      },
    },
    components: {
      ServiceNumberBillTotalLineChart,
      ServiceNumberMinutesOfUseLineChart,
      ServiceNumberDataNationalUsageLineChart,
      ServiceNumberDataOverseasUsageLineChart,
      // ServiceNumberDataUsageLineChart,
      ServiceDetail,
      ServiceNumberPrint
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      serviceNumber () {
        return this.snDataLocal
      }
    },
    data () {
      return {
        loaded: false,
        snDataLocal: null
      }
    },
    watch: {
      snData: {
        handler () {
          console.log('serviceNumberPage this.serviceNumber changed to ' + this.snData)
          this.snDataLocal = this.snData
          this.loaded = true
        },
      },
    },
    mounted () { // call after dom is created
      console.log('serviceNumberPage mounted snData ', this.snData)
      if (this.snData) {
        this.snDataLocal = this.snData
      } else { // when called directly from global search box
        console.log('ServiceNumberPage.vue mounted via route with sn ' + this.$route.params.sn)
        this.snDataLocal = this.$route.params.sn
      }
      this.$gtag.pageview({
        page_path: '/serviceNumberPage',
      })
    },
  }
</script>

<style scoped>

</style>
