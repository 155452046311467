<template>
  <v-card
    max-width="750px"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      Minutes of Use
    </v-card-title>
    <apexchart
      type="bar"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { findMonthlyDataByServiceNumber } from '@/api/PouchDaoReport'

  // https://codesandbox.io/s/apexcharts-and-vue-fmrxw?from-embed
  export default {
    name: 'ServiceNumberMinutesOfUserLineChart',
    props: {
      snData: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        series: [{
          name: 'Series 1',
          data: [80, 50, 30, 40, 100, 20],
        }, {
          name: 'Series 2',
          data: [20, 30, 40, 80, 20, 80],
        }],
        chartOptions: {
          colors: [ '#0d11ff', '#ea349d'],
          chart: {
            // height: 150,
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 1,
            // labels: {
            /**
             * Allows users to apply a custom formatter function to xaxis labels.
             *
             * @param { String } value - The default value generated
             * @param { Number } timestamp - In a datetime series, this is the raw timestamp
             * @param { index } index of the tick / currently executing iteration in xaxis labels array
             */
            // formatter: function (value, timestamp, index) {
            //   return moment(new Date(timestamp)).format('MMM YYYY')
            // },
            // },
          },
          // yaxis: {
          //     title: {
          //         text: 'Minutes of use'
          //     },
          //     min: 5,
          //     max: 40
          // },
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },

          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },

        },

      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('serviceNumber', {
        currentSn: 'currentSn',
      }),
    },
    watch: {
      currentSn: {
        handler () {
          console.log('ServiceNumberMinutesOfUserLineChart currentSn changed to ' + this.currentSn)
          this.refreshMinutesOfUse(this.currentSn)
        },
      },
      snData: {
        handler () {
          console.log('ServiceNumberMinutesOfUserLineChart snData changed to ' + this.snData)
          this.refreshMinutesOfUse(this.snData)
        },
      },
    },
    mounted () {
      console.log('ServiceNumberMinutesOfUserLineChart.vue mounted snData ' + this.snData + ' refreshRequired ' + this.refreshRequired)
      // this.refreshMinutesOfUse(this.snData)
    },
    methods: {
      refreshMinutesOfUse (sn) {
        console.log('refreshMinutesOfUse sn is ' + sn)
        if (!sn) {
          console.error('refreshMinutesOfUse sn is empty!')
          return
        }

        const nationalDataDurationPromise = findMonthlyDataByServiceNumber('DOMESTIC DATA', 'DURATION', sn)
        const nationalVoiceDurationPromise = findMonthlyDataByServiceNumber('DOMESTIC VOICE', 'DURATION', sn)
        const overseasDataDurationPromise = findMonthlyDataByServiceNumber('OVERSEAS DATA', 'DURATION', sn)
        Promise.all([nationalDataDurationPromise, nationalVoiceDurationPromise, overseasDataDurationPromise]).then(result => {
          const callMonthMap = new Map(this.customerInfo.yearMonthSelectList.map(i => [i.yearMonth, { yearMonth: i.yearMonth, data: 0, voice: 0, overseas: 0 }]))
          // console.log(callMonthMap)
          const nationalDataDuration = result[0][0].doc
          const nationalVoiceDuration = result[1][0].doc
          const overseasDuration = result[2][0].doc
          for (const pair of callMonthMap) {
            const [key, value] = pair // key is the yearMonth and value is {data,voice,oversears}
            value.data = nationalDataDuration[key]
            value.voice = nationalVoiceDuration[key]
            value.overseas = overseasDuration[key]
            // console.log(value)
          }
          // now prepare the chart series need to store the x as date time
          const dataSeries = []
          const voiceSeries = []
          const overseasSeries = []
          for (const pair of callMonthMap) {
            const [yearMonth, value] = pair // key is the yearMonth and value is {data,voice,oversears}
            dataSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.data)])
            voiceSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.voice)])
            overseasSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.overseas)])
          }
          // console.log(dataSeries)
          // console.log(voiceSeries)
          // console.log(overseasSeries)
          this.series = [{
            name: 'National Voice',
            data: voiceSeries,
          }, {
            name: 'International',
            data: overseasSeries,
          },
          ]
        })
      },

    },
  }
</script>

<style scoped>

</style>
