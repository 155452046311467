<template>
    <apexchart
      type="bar"
      height="100%"
      width="100%"
      :options="chartOptions"
      :series="series"
    />
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { findMonthlyDataByServiceNumber } from '@/api/PouchDaoReport'

  // https://codesandbox.io/s/apexcharts-and-vue-fmrxw?from-embed
  export default {
    name: 'ServiceNumberBillTotalLineChartPrint',
    props: {
      snData: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        series: [],
        chartOptions: {
          colors: ["#E91E63"],
          chart: {
            // height: 150,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "40%",
            },
          },
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 6,
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        }
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('serviceNumber', {
        currentSn: 'currentSn',
      }),
    },
    watch: {
      currentSn: {
        handler () {
          console.log('ServiceNumberBillTotalLineChart currentSn changed to ' + this.currentSn)
          this.refreshBillTotal(this.currentSn)
        },
      },
      snData: {
        handler () {
          console.log('ServiceNumberBillTotalLineChart snData changed to ' + this.snData)
          this.refreshBillTotal(this.snData)
        },
      },
    },
    mounted () {
      console.log('ServiceNumberBillTotalLineChart.vue mounted snData ' + this.snData + ' refreshRequired ' + this.refreshRequired)
      // this.refreshBillTotal(this.snData)
    },
    methods: {
      refreshBillTotal (sn) {
        console.log('refreshBillTotal sn is ' + sn)
        if (!sn) {
          console.error('refreshBillTotal sn is empty!')
          return
        }
        const nationalDataDurationPromise = findMonthlyDataByServiceNumber('BILL', 'TOTAL', sn)
        Promise.all([nationalDataDurationPromise]).then(result => {
          const callMonthMap = new Map(this.customerInfo.yearMonthSelectList.map(i => [i.yearMonth, { yearMonth: i.yearMonth, data: 0, voice: 0, overseas: 0 }]))
          console.log('refreshBillTotal nationalDataDurationPromise', callMonthMap)
          const nationalBillTotal = result[0][0].doc
          for (const pair of callMonthMap) {
            const [key, value] = pair // key is the yearMonth and value is {data,voice,oversears}
            value.bill = nationalBillTotal[key]
            // console.log(value)
          }
          // now prepare the chart series need to store the x as date time
          const dataSeries = []
          for (const pair of callMonthMap) {
            const [yearMonth, value] = pair // key is the yearMonth and value is {data,voice,oversears}
            dataSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.bill)])
          }
          this.series = [{
            name: 'Total ($)',
            data: dataSeries,
          }]
        })
      },

    },
  }
</script>

<style scoped>

</style>
