<template>
  <v-card
    class="pt-0 pb-0 mt-0 mb-0"
  >
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="series"
      height="320px"
      class="pl-1 pr-1 pb-0 mb-0"
    />
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import moment from 'moment'
  import Utils from '@/api/util'
  export default {
    name: 'ConfiguredOptionsListChart',
    computed: {
      ...mapGetters('configuredOption', {
        heroGraphData: 'heroGraphData',
      }),
      // ...mapGetters('dashboard', {
      //   billTotal12Months: 'billTotal12Months', // refer to actions.js updateBillTotal12MonthChart
      // }),
    },
    data () {
      return {
        series: [],
        chartOptions: {
          colors: ['#1CB0FC'],
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.8,
              opacityTo: 0.6,
              stops: [0, 100],
              colorStops: [],
            },
          },
          stroke: {
            width: 0.3,
          },
          plotOptions: {
            bar: {
              columnWidth: '20%',
            },
          },
          xaxis: {
            labels: {
              // formatter: function (value) {
              //   const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
              //   return dateValue.format('MMM YY')
              // },
              style: {
                colors: '#4D4D4D',
                fontSize: '10px',
              },
            },
          },
          yaxis: {
            // labels: {
            //   formatter: function (value) {
            //     return Utils.formatCurrency(value)
            //   },
            // },
          },
          tooltip: {
            // x: {
            //   format: 'MMM yyyy',
            // },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        },
      }
    },
    watch: {
      heroGraphData: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateConfiguredOptionsChart()
        },
      },
    },
    mounted () {
      console.log('ConfiguredOptionsListChart mounted')
      if (this.heroGraphData) {
        this.updateConfiguredOptionsChart()
      }
    },
    methods: {
      updateConfiguredOptionsChart () {
        console.log('updateConfiguredOptionsChart () data changed  latest ', this.heroGraphData)
        let chartData = this.heroGraphData.map(data => data.count)
        let chartCategory = this.heroGraphData.map(data => data.label)
        this.series = [{
          name: 'Count',
          data: chartData,
          // data: chartData,
        }]
        this.chartOptions = {
          labels: chartCategory, // !! do not change this as the topTenPointSelected() needs this to lookup the item click in order to filter the equipment table
        }
      },
    },
  }
</script>

<style scoped>

</style>
