<template>
  <v-card>
    <v-system-bar
      color="grey"
      dark
    >
      <v-icon
        @click="expandTable"
        v-if="expanded"
      >
        mdi-window-minimize
      </v-icon>

      <v-icon
        @click="expandTable"
        v-if="!expanded"
      >
        mdi-window-maximize
      </v-icon>
      <v-spacer />

      <!-- <v-menu
        v-show="false"
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Select Columns</v-card-title>
          <v-divider></v-divider>

          <v-list>
            <v-list-item
              v-for="tag in tags"
              :key="tag.name"
            >
              <v-list-item-action>
                <v-switch
                  color="purple"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>{{ tag.text }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="message"
                  color="purple"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Enable messages</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="hints"
                  color="purple"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Enable hints</v-list-item-title>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="menu = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu> -->
    </v-system-bar>

    <v-row>
      <v-col>
        <v-simple-table
          min-height="800px"
          max-height="1200px"
        >
          <tr>
            <td
              width="210px"
              style="vertical-align:top;padding-top:80px;"
            >
              <v-card
                class="innerbody"
                style="border-right:1px solid #EEEEEE"
              >
                <v-chip-group
                  v-model="selectedOptions"
                  active-class="deep-purple--text text--accent-2"
                  column
                  multiple
                  class="pl-2"
                >
                  <v-chip
                    v-for="tag in tags"
                    :key="tag.name"
                    filter
                    outlined
                    :disabled="tag.disabled"
                  >
                    {{ tag.text }}
                  </v-chip>
                </v-chip-group>
              </v-card>
            </td>
            <td
              style="vertical-align:top"
              class="OnHover"
            >
              <v-data-table
                :headers="headers"
                :items="tableData"
                loading-text="Loading data...."
                :loading="loading"
                :options="dataTableOptions"
                dense
                class="centerheadings"
              >
                <template v-slot:item="{ item, select }">
                  <tr @click="handleRowClick(item)">
                    <td
                      style="min-width:120px"
                      class="ServiceText"
                    >
                      <v-icon v-if="item.rowIndex == selectedRow">
                        mdi-forward
                      </v-icon>
                      {{ item.sn }}
                    </td>
                    <td
                      style="min-width:220px"
                    >
                      {{ item.userName }}
                    </td>
                    <template v-for="header in headers ">
                      <template v-if="header.optionField">
                        <td
                          :key="header.value+item.sn"
                          style="min-width:60px"
                        >
                          <div
                            style="text-align:center"
                          >
                            <v-icon
                              v-if="item[header.value] === 'YES' || item[header.value] === 'Y'"
                              color="green darken-2"
                            >
                              mdi-check-bold
                            </v-icon>
                            <v-icon
                              v-if="item[header.value] === 'NO' || item[header.value] === 'N'"
                              color="grey lighten-1"
                            >
                              clear
                            </v-icon>
                          </div>
                        </td>
                      </template>
                    </template>
                    <td
                      v-if="biltzFileVersion == '2.0'"
                      style="min-width:220px"
                    >
                      {{ item.networkFeatureCode ? item.networkFeatureCode : '' }}
                    </td>
                    <td
                      v-if="biltzFileVersion == '2.0'"
                      style="min-width:220px"
                    >
                      {{ item.messagebankCat ? item.messagebankCat : '' }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <!-- <download-excel
                :fields="excelHeaderRow"
                :name="excelFileName"
                :fetch="exportToExcel"
                :title="excelTitle"
                :meta="excelMeta"
                :before-generate="beforeGenerateExcel"
                :before-finish="beforeFinishExcel"
              >
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 pa-0"
                  align="left"
                >
                  Download
                  <v-icon
                    small
                    class="mr-1"
                    right
                  >
                    mdi-download
                  </v-icon>
                </v-btn>
              </download-excel> -->
            </td>
          </tr>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import moment from 'moment'
  import Utils from '@/api/util'
  import { configuredOptions, filterUsers } from '@/api/ConfiguredOptionReport'
  import store from '@/store'

  export default {
    name: 'ConfiguredOptionsTable',
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('configuredOption', {
        heroGraphData: 'heroGraphData',
      }),
      excelFileName () {
        return 'configuredOptions.xls'
      },
      excelTitle () {
        return 'Configure Options'
      },
    },
    data () {
      return {
        notifications: [
          'Mike John Responded to your email',
          'You have 5 new tasks',
          'You\'re now friends with Andrew',
          'Another Notification',
          'Another one',
        ],
        expanded: false,
        selectedRow: -1,
        excelHeaderRow: {},
        excelMeta: [
          [
            {
              key: 'charset',
              value: 'utf-8',
            },
          ],
        ],

        loading: false,
        tags: [], // for v-chip
        selectedOptions: [],
        baseHeaders: [
          {
            text: 'Mobile',
            align: 'start',
            value: 'sn',
          },
          {
            text: 'User Name',
            value: 'userName',
          },
        ],
        message:[],
        hints:[],
        headers: [],
        tableData: [],
        dataTableOptions: {
          itemsPerPage: 50,
        // sortBy: ['201711'],
        // sortDesc: [true]
        },
        biltzFileVersion: ''
      }
    },
    watch: {
      selectedOptions: 'filterServiceNumberWithOptions',
      heroGraphData: 'heroGraphDataChanged',
    },
    mounted () {
      // console.log('ConfiguredOptionsTable mounted')
      this.init()
    },
    methods: {
      init () {
        // console.log('init data is ', this.heroGraphData)
        this.biltzFileVersion = this.customerInfo.biltzFileVersion
        this.heroGraphDataChanged()
      },
      expandTable () {
        this.expanded = !this.expanded
        console.log('expandTable() ', this.expanded)
        this.$emit('expand', this.expanded)
      },
      heroGraphDataChanged () {
        this.selectedOptions = [] // reset
        this.headers = [...this.baseHeaders]
        this.tags = this.heroGraphData.map(data => ({
          name: data.name,
          label: data.label,
          count: data.count,
          text: data.label + ' (' + data.count + ')',
          disabled: data.count === 0,
        }))
        this.tags.forEach(configuredOption => {
          if (configuredOption.count > 0) {
            this.headers.push({
              text: configuredOption.label,
              align: 'center',
              value: configuredOption.name,
              optionField: true,
            })
          }
        })
        if (this.biltzFileVersion == '2.0'){
          this.headers.push({
            text: 'Network Feature Code',
            value:'networkFeatureCode'
          },
          {
            text: 'Message Bank Cat',
            value: 'messagebankCat',
          })
        }
        this.filterServiceNumberWithOptions()
      },
      filterServiceNumberWithOptions () {
        const searchOptions = []
        this.loading = true
        this.selectedOptions.forEach(optionIndex => {
          searchOptions.push(this.tags[optionIndex].name)
        })
        return filterUsers(searchOptions).then(result => {
          console.log('filterWithOptions user account found ' + result.length)
          // need to add row index....
          let rowIndex = 0
          result.forEach(serviceNumber => {
            serviceNumber.rowIndex = rowIndex++
          })
          this.tableData = result
          this.selectedRow = -1
          this.loading = false
        })
      },
      handleRowClick (value) {
        console.log('handleRowClick rowindex is ' + value.rowIndex)
        this.selectedRow = value.rowIndex
        store.dispatch('configuredOption/updateSnFilter', { sn: value.sn })
      },
      beforeGenerateExcel () {
        store.dispatch('app/setProgress', true)
      },
      beforeFinishExcel () {
        store.dispatch('app/setProgress', false)
      },
      exportToExcel () {
        console.log('excelHeader', this.excelHeaderRow)
        console.log('first row of table data is ', this.tableData[0])
        return this.tableData
      },
    },
  }
</script>

<style scoped>

</style>
