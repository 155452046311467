<template>
  <v-container
    class="pt-0"
    style="min-width:1366px;max-width:1920px;"
  >
    <!-- <v-row
      class="text-center pt-0"
    >
      <v-col class="mb-0 pt-0 mt-0">
        <configured-options-list-chart />
      </v-col>
    </v-row>
    <configured-options-table />-->
    <template v-if="showSnDetail">
      <v-breadcrumbs
        :items="crumbs"
        style="padding-top: 0"
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="props">
          <v-chip
            :color="!props.item.disabled?'':'#FCDBE6'"
            :text-color="!props.item.disabled?'':'#EB3573'"
            @click="handleBreadCrumbClick(props.item.code)"
          >
            {{ props.item.text }}
          </v-chip>
        </template>
      </v-breadcrumbs>
      <service-number-page :sn-data="snFilter.sn" />
    </template>
    <component
      :is="currentComponent"
      v-show="showHomePage"
    />
  </v-container>
</template>

<script>
  import ConfiguredOptionsComponent from '@/views/blitz/pages/ConfiguredOptionsComponent'
  import ServiceNumberPage from '@/views/blitz/pages/ServiceNumberPage'

  import VueHtml2pdf from 'vue-html2pdf'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ConfiguredOptions',
    components: {
      ConfiguredOptionsComponent,
      ServiceNumberPage,
      VueHtml2pdf,
    },
    computed: {
      ...mapGetters('configuredOption', {
        snFilter: 'snFilter', //, ...
      }),
      showHomePage () {
        console.log('showHomePage() returning ' + !this.showSnDetail)
        return !this.showSnDetail
      },
    },
    data () {
      return {
        showSnDetail: false,
        currentComponent: 'ConfiguredOptionsComponent',
        crumbs: [
          {
            text: 'Configured Options',
            disabled: false,
            code: 'configuredOptions',
            href: 'breadcrumbs_dashboard',
          },
        ],
        table_crumbs: [
          {
            text: 'Configured Options',
            disabled: false,
            code: 'configuredOptions',
            href: 'breadcrumbs_dashboard',
          },
        ],
        sn_crumbs: [
          {
            text: 'Configured Options',
            disabled: false,
            code: 'configuredOptions',
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Service Detail',
            code: 'serviceNumber',
            disabled: true,
            href: 'breadcrumbs_dashboard',
          },
        ],

      }
    },
    watch: {
      snFilter: 'handleServiceNumberClick'
    },
    mounted () {
      this.$gtag.pageview({
        page_path: '/configuredOptions',
      })
    },
    methods: {
      onProgress () {
        console.log('onProgress')
      },
      hasGenerated () {
        console.log('hasGenerated')
      },
      printPage () {
        this.$refs.html2Pdf.generatePdf()
      },
      handleServiceNumberClick () {
        console.log('snFilter change to ' + this.snFilter)
        this.crumbs = this.sn_crumbs
        this.showSnDetail = true
      },
      handleBreadCrumbClick (code) {
        console.log('handleBreadCrumbClick value is ' + code)
        this.showSnDetail = false
        this.crumbs = this.table_crumbs
      },
    },
  }
</script>

<style scoped>

</style>
