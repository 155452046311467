<template>
  <v-card
    max-width="750px"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      National Data Usage (Mb)
    </v-card-title>
    <apexchart
      type="area"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { findMonthlyDataByServiceNumber } from '@/api/PouchDaoReport'

  // https://codesandbox.io/s/apexcharts-and-vue-fmrxw?from-embed
  export default {
    name: 'ServiceNumberDataNationalUsageLineChart',
    props: {
      snData: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        series: [],
        chartOptions: {
          colors: ['#21e87f', '#0d11ff', '#fbaa39', '#ea349d'],
          chart: {
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          fill: {
            type: 'solid',
            opacity: [0.35, 1],
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 6,
          // labels: {
          /**
           * Allows users to apply a custom formatter function to xaxis labels.
           *
           * @param { String } value - The default value generated
           * @param { Number } timestamp - In a datetime series, this is the raw timestamp
           * @param { index } index of the tick / currently executing iteration in xaxis labels array
           */
          },
          yaxis: [
            {
              seriesName: 'National Usage',
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#21e87f',
              },
              labels: {
                style: {
                  color: '#21e87f',
                },
              },
              title: {
                text: 'National Usage',
                style: {
                  color: '#21e87f',
                },
              },
              tooltip: {
                enabled: true,
              },
            },
            {
              seriesName: 'National Excess',
              opposite: true,

              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#fbaa39',
              },
              labels: {
                style: {
                  color: '#fbaa39',
                },
              },
              title: {
                text: 'National Excess',
                style: {
                  color: '#fbaa39',
                },
              },
              tooltip: {
                enabled: true,
              },
            },
          ],
          // yaxis: {
          //     title: {
          //         text: 'Minutes of use'
          //     },
          //     min: 5,
          //     max: 40
          // },
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },

        },

      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('serviceNumber', {
        currentSn: 'currentSn',
      }),
    },
    watch: {
      currentSn: {
        handler () {
          console.log('ServiceNumberDataUsageLineChart currentSn changed to ' + this.currentSn)
          this.refreshUsage(this.currentSn)
        },
      },
      snData: {
        handler () {
          console.log('ServiceNumberDataNationalUsageLineChart snData changed to ' + this.snData)
          this.refreshUsage(this.snData)
        },
      },

    },
    mounted () {
      console.log('ServiceNumberDataNationalUsageLineChart.vue mounted snData ' + this.snData + ' refreshRequired ' + this.refreshRequired)
    // this.refreshUsage(this.snData)
    },
    methods: {
      refreshUsage (sn) {
        console.log('refreshUsage sn is ' + sn)
        if (!sn) {
          console.error('refreshUsage sn is empty!')
          return
        }
        const nationalDataUsagePromise = findMonthlyDataByServiceNumber('DOMESTIC DATA', 'USAGE', sn)
        // const overseasDataUsagePromise = findMonthlyDataByServiceNumber('OVERSEAS DATA', 'USAGE', sn)
        const nationalDataExcessPromise = findMonthlyDataByServiceNumber('DOMESTIC DATA', 'EXCESS', sn)
        // const overseasDataExcessPromise = findMonthlyDataByServiceNumber('OVERSEAS DATA', 'EXCESS', sn)
        Promise.all([nationalDataUsagePromise, nationalDataExcessPromise]).then(result => {
          console.log('refreshUsage sn is ' + sn + ' completed')
          const callMonthMap = new Map(this.customerInfo.yearMonthSelectList.map(i => [i.yearMonth, { yearMonth: i.yearMonth, data: 0, overseas: 0, domesticExcess: 0.0, overseasExcess: 0.0 }]))
          const nationalUsage = result[0][0].doc
          const nationalExcess = result[1][0].doc
          for (const pair of callMonthMap) {
            const [key, value] = pair // key is the yearMonth and value is {data,voice,oversears}
            value.data = nationalUsage[key]
            value.domesticExcess = nationalExcess[key]
          }
          // now prepare the chart series need to store the x as date time
          const dataSeries = []
          const domesticExcessSeries = []
          for (const pair of callMonthMap) {
            const [yearMonth, value] = pair // key is the yearMonth and value is {data,voice,oversears}
            dataSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.data)])
            // overseasSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.overseas)])
            domesticExcessSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.domesticExcess)])
            // overseasExcessSeries.push([moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(value.overseasExcess)])
          }
          this.series = [
            {
              name: 'National Usage',
              data: dataSeries,
            },
            {
              name: 'National Excess',
              data: domesticExcessSeries,
            },
          ]
        })
      },

    },
  }
</script>

<style scoped>

</style>
