<template>
  <v-container
  style="min-width:1366px;max-width:1920px;"
  >
    <v-expand-transition>
    <v-row
      ref="content"
      v-if="expandTable"
    >
      <v-col class="mb-0 pt-0 mt-0">
        <configured-options-list-chart />
      </v-col>
    </v-row>
    </v-expand-transition>
    <v-expand-transition>
    <configured-options-table
      v-on:expand="tableExpandEvent"
    />
    </v-expand-transition>
  </v-container>
</template>

<script>
  import ConfiguredOptionsListChart from '@/views/blitz/components/apex/ConfiguredOptionsListChart'
  import ConfiguredOptionsTable from '@/views/blitz/components/ConfiguredOptionsTable'

  export default {
    name: 'ConfiguredOptionsComponent',
    components: {
      ConfiguredOptionsListChart,
      ConfiguredOptionsTable,
    },
    data () {
      return {
        expandTable: true,
      }
    },
    methods: {
      tableExpandEvent (expand) {
        this.expandTable = !expand
        console.log('table expand event received ', expand)
      }
    }
  }
</script>

<style scoped>

</style>
