<template>
  <v-container>
    <v-card
      class="pa-3"
      max-width="750px"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            class="pa-0"
          >
            <v-card class="innerbody">
              <div class="Service_Title">
                {{ serviceNumberTitle }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        class="mx-auto"
        width="90%"
      />

      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-card-title class="pl-0 font-weight-light">
                Account
              </v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ accountNumber }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-card-title class="pl-0 font-weight-light">
                User Name
              </v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ userName }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        class="mx-auto"
        width="90%"
      />

      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-card-title class="pl-0 font-weight-light">
                Device
              </v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ deviceName }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Category
                    </td>
                    <td class="text--primary">
                      {{ deviceType }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Network
                    </td>
                    <td class="text--primary">
                      {{ deviceCapability }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      IMEI
                    </td>
                    <td class="text--primary">
                      {{ imei }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-card-title class="pl-0 font-weight-light">
                Sim Details
              </v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ sim }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Sim Network
                    </td>
                    <td class="text--primary">
                      {{ simNetwork }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Activation
                    </td>
                    <td class="text--primary">
                      {{ serviceActivationDate }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        class="mx-auto"
        width="90%"
      />

      <v-container>
        <v-row>
          <v-col
            sm="12"
            class="innerbody pa-0 d-flex"
          >
            <div
              class="v-card__title pl-0 pb-0 pt-5 font-weight-light"
              width="100%"
            >
              Associated Plans
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ flexiPlanName }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ flexiPlan }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Start Date
                    </td>
                    <td class="text--primary">
                      {{ flexiStart }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="dataPlan1 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ dataPlan1Description }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ dataPlan1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Mobthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ dataPlan1MonthlyCommitment }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="dataPlan2 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ dataPlan2Description }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ dataPlan2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ dataPlan2MonthlyCommitment }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="shareableDataPlan > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ shareablePlanName }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ shareableDataPlan }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ shareablePlanFee }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="shareableDataPlan2 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ shareablePlanName2 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ shareableDataPlan2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ shareablePlanFee2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="shareableBonusDataPlan1 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ shareableBonusPlanName1 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ shareableBonusDataPlan1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ shareableBonusPlanFee1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="shareableBonusDataPlan2 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ shareableBonusPlanName2 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ shareableBonusDataPlan2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ shareableBonusPlanFee2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="nonShareableDataPlan1 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ nonShareablePlanName1 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ nonShareableDataPlan1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ nonShareablePlanFee1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="nonShareableDataPlan2 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ nonShareablePlanName2 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ nonShareableDataPlan2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ nonShareablePlanFee2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="nonShareableBonusDataPlan1 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ nonShareableBonusPlanName1 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ nonShareableBonusDataPlan1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ nonShareableBonusPlanFee1 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col
            v-if="nonShareableBonusDataPlan2 > ''"
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ nonShareableBonusPlanName2 }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Code
                    </td>
                    <td class="text--primary">
                      {{ nonShareableBonusDataPlan2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Monthly Fee:
                    </td>
                    <td class="text--primary">
                      ${{ nonShareableBonusPlanFee2 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header" />
                    <td class="text--primary" />
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        v-if="mro1Contract > ''"
        class="mx-auto"
        width="90%"
      />

      <v-container
        v-if="mro1Contract > ''"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="pa-0 d-flex"
          >
            <v-card class="innerbody">
              <v-card-title class="pl-0 font-weight-light">
                MRO Contract
              </v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <tr>
                    <td
                      colspan="2"
                      class="title"
                    >
                      {{ mro1Contract }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="90px"
                      class="text--header"
                    >
                      Monthly Fee
                    </td>
                    <td class="text--primary">
                      ${{ mro1ContractMonthlyCommitment }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      Start Date
                    </td>
                    <td class="text--primary">
                      {{ mro1ContractStartDate }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text--header">
                      End Date
                    </td>
                    <td class="text--primary">
                      {{ mro1ContractEndDate }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        class="mx-auto"
        width="90%"
      />

      <v-container>
        <v-row>
          <v-col
            sm="12"
            class="pa-0 justify-space-around d-flex"
          >
            <v-card class="innerbody">
              <v-card-title class="pa-0 font-weight-light ">
                Configured Options
              </v-card-title>
              <v-chip
                class="ma-2"
                :color="(internationalRoamingFlag)? 'teal': 'default'"
                :text-color="(internationalRoamingFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="internationalRoamingFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!internationalRoamingFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  International Roaming
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(mmsAccessFlag)? 'teal': 'default'"
                :text-color="(mmsAccessFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="mmsAccessFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!mmsAccessFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  MMS Access
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(barOutgoingVoiceFlag)? 'teal': 'default'"
                :text-color="(barOutgoingVoiceFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barOutgoingVoiceFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barOutgoingVoiceFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Outgoing Voice
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(barOutgoingSMSFlag)? 'teal': 'default'"
                :text-color="(barOutgoingSMSFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barOutgoingSMSFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barOutgoingSMSFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Outgoing SMS
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(barIncomingSMSFlag)? 'teal': 'default'"
                :text-color="(barIncomingSMSFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barIncomingSMSFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barIncomingSMSFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Incoming SMS
                </div>
              </v-chip>
              <!-- <v-chip
                class="ma-2"
                :color="(spendLimitActiveFlag)? 'teal': 'default'"
                :text-color="(spendLimitActiveFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="spendLimitActiveFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!spendLimitActiveFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Spend Limit Active
                </div>
              </v-chip> -->
              <!-- <v-chip
                class="ma-2"
                :color="(videoCallingAccessFlag)? 'teal': 'default'"
                :text-color="(videoCallingAccessFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="videoCallingAccessFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!videoCallingAccessFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Video Calling Access
                </div>
              </v-chip> -->
              <!-- <v-chip
                class="ma-2"
                :color="(barVideoCallsFlag)? 'teal': 'default'"
                :text-color="(barVideoCallsFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barVideoCallsFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barVideoCallsFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Video Calls
                </div>
              </v-chip> -->
              <!-- <v-chip
                class="ma-2"
                :color="(barNextGInternetAccessFlag)? 'teal': 'default'"
                :text-color="(barNextGInternetAccessFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barNextGInternetAccessFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barNextGInternetAccessFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Next-G Internet
                </div>
              </v-chip> -->
              <!-- <v-chip
                class="ma-2"
                :color="(barNextGMMSFlag)? 'teal': 'default'"
                :text-color="(barNextGMMSFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barNextGMMSFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barNextGMMSFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Next-G MMS
                </div>
              </v-chip> -->
              <!-- <v-chip
                class="ma-2"
                :color="(barNextGWAPFlag)? 'teal': 'default'"
                :text-color="(barNextGWAPFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barNextGWAPFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barNextGWAPFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Next-G WAP
                </div>
              </v-chip> -->
              <v-chip
                class="ma-2"
                :color="(barPremiumSSMSFlag)? 'teal': 'default'"
                :text-color="(barPremiumSSMSFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barPremiumSSMSFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barPremiumSSMSFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Premium SSMS
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(telstraCorpFlag)? 'teal': 'default'"
                :text-color="(telstraCorpFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="telstraCorpFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!telstraCorpFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Telstra Corp
                </div>
              </v-chip>
              <!-- <v-chip
                class="ma-2"
                :color="(telstraDataPackFlag)? 'teal': 'default'"
                :text-color="(telstraDataPackFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="telstraDataPackFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!telstraDataPackFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Telstra Data Pack
                </div>
              </v-chip> -->
              <!-- <v-chip
                class="ma-2"
                :color="(telstraPCPackFlag)? 'teal': 'default'"
                :text-color="(telstraPCPackFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="telstraPCPackFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!telstraPCPackFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Telstra PC Pack
                </div>
              </v-chip> -->
              <v-chip
                class="ma-2"
                :color="(telstraXtranetFlag)? 'teal': 'default'"
                :text-color="(telstraXtranetFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="telstraXtranetFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!telstraXtranetFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Telstra Xtranet
                </div>
              </v-chip> 
              <v-chip
                class="ma-2"
                :color="(oneNumberFlagFlag)? 'teal': 'default'"
                :text-color="(oneNumberFlagFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="oneNumberFlagFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!oneNumberFlagFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  One Number Flag
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(barRoamingFlag)? 'teal': 'default'"
                :text-color="(barRoamingFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barRoamingFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barRoamingFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar Roaming
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(barIDDFlag)? 'teal': 'default'"
                :text-color="(barIDDFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="barIDDFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!barIDDFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Bar IDD
                </div>
              </v-chip>
              <v-chip
                class="ma-2"
                :color="(usernamePrintOnBillFlag)? 'teal': 'default'"
                :text-color="(usernamePrintOnBillFlag)? 'white': 'black'"
              >
                <v-avatar left>
                  <v-icon v-if="usernamePrintOnBillFlag">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-if="!usernamePrintOnBillFlag">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </v-avatar>
                <div style="width:110px">
                  Username Print on Bill
                </div>
              </v-chip>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { findSn } from '@/api/PouchDaoReport'
  import Utils from '@/api/util'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ServiceNumberDetail',
    props: {
      snData: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        serviceNumberTitle: '',
        serviceActivationDate: '2019',
        accountNumber: '',
        userName: '',
        sim: '',
        simNetwork: '',
        deviceName: '',
        deviceType: '',
        deviceCapability: '',
        deviceCategory: '',
        imei: '',
        flexiPlan: '',
        flexiPlanName: '',
        flexiStart: '',
        flexiEnd: '',
        dataPlan1: '',
        dataPlan1Description: '',
        dataPlan1MonthlyCommitment: '',
        dataPlan2: '',
        dataPlan2Description: '',
        dataPlan2MonthlyCommitment: '',
        shareableDataPlan: '',
        shareablePlanName: '',
        shareablePlanFee: '',
        shareableDataPlan2: '',
        shareablePlanName2: '',
        shareablePlanFee2: '',
        shareableBonusPlanName1: '',
        shareableBonusDataPlan1: '',
        shareableBonusPlanFee1: '',
        shareableBonusPlanName2: '',
        shareableBonusDataPlan2: '',
        shareableBonusPlanFee2: '',
        nonShareablePlanName1: '',
        nonShareableDataPlan1: '',
        nonShareablePlanFee1: '',
        nonShareablePlanName2: '',
        nonShareableDataPlan2: '',
        nonShareablePlanFee2: '',
        nonShareableBonusPlanName1: '',
        nonShareableBonusDataPlan1: '',
        nonShareableBonusPlanFee1: '',
        nonShareableBonusPlanName2: '',
        nonShareableBonusDataPlan2: '',
        nonShareableBonusPlanFee2: '',
        internationalRoaming: '',
        internationalRoaming: '',
        serviceContractStart: '',
        serviceContractEnd: '',
        mro1Contract: '',
        mro1ContractStartDate: '',
        mro1ContractEndDate: '',
        mro1ContractMonthlyCommitment: '',
        barOutgoingVoice: '',
        barOutgoingSMS: '',
        barIncomingSMS: '',
        barPremiumSSMS: '',
        spendLimitActive: '',
        mmsAccess: '',
        videoCallingAccess: '',
        telstraCorp: '',
        telstraXtranet: '',
        telstraDataPack: '',
        telstraPCPack: '',
        barNextGWAP: '',
        barNextGMMS: '',
        barNextGInternetAccess: '',
        barVideoCalls: '',
        oneNumberFlag: '',
        barRoaming: '',
        barIDD: '',
        usernamePrintOnBill: '',

      }
    },
    computed: {
      ...mapGetters('serviceNumber', {
        currentSn: 'currentSn',
      }),
      internationalRoamingFlag () {
        if (this.internationalRoaming > '' && this.internationalRoaming === 'YES') {
          return true
        } else {
          return false
        }
      },
      barOutgoingSMSFlag () {
        if (this.barOutgoingSMS > '' && this.barOutgoingSMS === 'YES') {
          return true
        } else {
          return false
        }
      },
      barOutgoingVoiceFlag () {
        if (this.barOutgoingVoice > '' && this.barOutgoingVoice === 'YES') {
          return true
        } else {
          return false
        }
      },
      barPremiumSSMSFlag () {
        if (this.barPremiumSSMS > '' && this.barPremiumSSMS === 'YES') {
          return true
        } else {
          return false
        }
      },
      barIncomingSMSFlag () {
        if (this.barIncomingSMS > '' && this.barIncomingSMS === 'YES') {
          return true
        } else {
          return false
        }
      },
      barNextGInternetAccessFlag () {
        if (this.barNextGInternetAccess > '' && this.barNextGInternetAccess === 'YES') {
          return true
        } else {
          return false
        }
      },
      barNextGWAPFlag () {
        if (this.barNextGWAP > '' && this.barNextGWAP === 'YES') {
          return true
        } else {
          return false
        }
      },
      barNextGMMSFlag () {
        if (this.barNextGMMS > '' && this.barNextGMMS === 'YES') {
          return true
        } else {
          return false
        }
      },
      spendLimitActiveFlag () {
        if (this.spendLimitActive > '' && this.spendLimitActive === 'YES') {
          return true
        } else {
          return false
        }
      },
      barPremiumSSMSFlag () {
        if (this.barPremiumSSMS > '' && this.barPremiumSSMS === 'YES') {
          return true
        } else {
          return false
        }
      },
      mmsAccessFlag () {
        if (this.mmsAccess > '' && this.mmsAccess === 'YES') {
          return true
        } else {
          return false
        }
      },
      videoCallingAccessFlag () {
        if (this.videoCallingAccess > '' && this.videoCallingAccess === 'YES') {
          return true
        } else {
          return false
        }
      },
      telstraCorpFlag () {
        if (this.telstraCorp > '' && this.telstraCorp === 'YES') {
          return true
        } else {
          return false
        }
      },
      telstraXtranetFlag () {
        if (this.telstraXtranet > '' && this.telstraXtranet === 'YES') {
          return true
        } else {
          return false
        }
      },
      telstraDataPackFlag () {
        if (this.telstraDataPack > '' && this.telstraDataPack === 'YES') {
          return true
        } else {
          return false
        }
      },
      telstraPCPackFlag () {
        if (this.telstraPCPack > '' && this.telstraPCPack === 'YES') {
          return true
        } else {
          return false
        }
      },
      barVideoCallsFlag () {
        if (this.barVideoCalls > '' && this.barVideoCalls === 'YES') {
          return true
        } else {
          return false
        }
      },
      oneNumberFlagFlag () {
        if (this.oneNumberFlag > '' && this.oneNumberFlag === 'YES') {
          return true
        } else {
          return false
        }
      },
      barRoamingFlag () {
        if (this.barRoaming > '' && this.barRoaming === 'YES') {
          return true
        } else {
          return false
        }
      },
      barIDDFlag () {
        if (this.barIDD > '' && this.barIDD === 'YES') {
          return true
        } else {
          return false
        }
      },
      usernamePrintOnBillFlag () {
        if (this.usernamePrintOnBill > '' && this.usernamePrintOnBill === 'Y') {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      currentSn: {
        handler () {
          console.log('ServiceNumberDetail currentSn changed to ' + this.currentSn)
          this.loadDetail(this.currentSn)
        },
      },
      snData: {
        handler () {
          console.log('ServiceNumberDetail snData changed to ' + this.snData)
          this.loadDetail(this.snData)
        },
      },
    },
    mounted () {
      console.log('ServiceNumberDetail mounted snData is ' + this.snData)
      // this.loadDetail(this.snData)
    },
    methods: {
      loadDetail (snData) {
        findSn(snData).then(result => {
          console.log('loadDetail sn detail loaded for sn ' + snData + ' result is', result)
          this.serviceNumberTitle = snData
          this.serviceActivationDate = moment(result.snActivationDate).format('DD MMM YYYY')
          this.accountNumber = result.acct
          this.userName = result.userName
          this.imei = result.imei
          this.sim = result.sim
          this.simNetwork = result.simNetwork
          this.deviceName = result.deviceName
          this.deviceType = result.deviceType
          this.deviceCapability = result.deviceCapability
          this.deviceCategory = result.deviceCategory
          this.flexiPlan = result.flexiPlan
          this.flexiPlanName = result.flexiPlanName
          this.flexiStart = moment(result.flexiStart).format('DD MMM YYYY')
          this.flexiEnd = moment(result.flexiEnd).format('DD MMM YYYY')

          this.internationalRoaming = result.internationalRoaming
          this.serviceContractStart = result.serviceContractStart
          this.serviceContractEnd = result.serviceContractEnd
          this.mro1Contract = result.mro1Contract
          this.mro1ContractStartDate = result.mro1ContractStartDate
          this.mro1ContractEndDate = result.mro1ContractEndDate
          this.mro1ContractMonthlyCommitment = result.mro1ContractMonthlyCommitment
          this.dataPlan1 = result.dataPlan1
          this.dataPlan1Description = result.dataPlan1Description
          this.dataPlan1MonthlyCommitment = result.dataPlan1MonthlyCommitment
          this.dataPlan2 = result.dataPlan2
          this.dataPlan2Description = result.dataPlan2Description
          this.dataPlan2MonthlyCommitment = result.dataPlan2MonthlyCommitment
          this.shareableDataPlan = result.shareableDataPlan
          this.shareablePlanName = result.shareablePlanName
          this.shareablePlanFee = result.shareablePlanFee
          this.shareableDataPlan2 = result.shareableDataPlan2
          this.shareablePlanName2 = result.shareablePlanName2
          this.shareablePlanFee2 = result.shareablePlanFee2
          this.shareableBonusPlanName1 = result.shareableBonusPlanName1
          this.shareableBonusDataPlan1 = result.shareableBonusDataPlan1
          this.shareableBonusPlanFee1 = result.shareableBonusPlanFee1
          this.shareableBonusPlanName2 = result.shareableBonusPlanName2
          this.shareableBonusDataPlan2 = result.shareableBonusDataPlan2
          this.shareableBonusPlanFee2 = result.shareableBonusPlanFee2
          this.nonShareablePlanName1 = result.nonShareablePlanName1
          this.nonShareableDataPlan1 = result.nonShareableDataPlan1
          this.nonShareablePlanFee1 = result.nonShareablePlanFee1
          this.nonShareablePlanName2 = result.nonShareablePlanName2
          this.nonShareableDataPlan2 = result.nonShareableDataPlan2
          this.nonShareablePlanFee2 = result.nonShareablePlanFee2
          this.nonShareableBonusPlanName1 = result.nonShareableBonusPlanName1
          this.nonShareableBonusDataPlan1 = result.nonShareableBonusDataPlan1
          this.nonShareableBonusPlanFee1 = result.nonShareableBonusPlanFee1
          this.nonShareableBonusPlanName2 = result.nonShareableBonusPlanName2
          this.nonShareableBonusDataPlan2 = result.nonShareableBonusDataPlan2
          this.nonShareableBonusPlanFee2 = result.nonShareableBonusPlanFee2
          this.barOutgoingVoice = result.barOutgoingVoice
          this.barOutgoingSMS = result.barOutgoingSMS
          this.barIncomingSMS = result.barIncomingSMS
          this.barPremiumSSMS = result.barPremiumSSMS
          this.spendLimitActive = result.spendLimitActive
          this.mmsAccess = result.mmsAccess
          this.videoCallingAccess = result.videoCallingAccess
          this.telstraCorp = result.telstraCorp
          this.telstraXtranet = result.telstraXtranet
          this.telstraDataPack = result.telstraDataPack
          this.telstraPCPack = result.telstraPCPack
          this.barNextGWAP = result.barNextGWAP
          this.barNextGMMS = result.barNextGMMS
          this.barNextGInternetAccess = result.barNextGInternetAccess
          this.barVideoCalls = result.barVideoCalls

          this.oneNumberFlag = result.oneNumberFlag
          this.barRoaming = result.barRoaming
          this.barIDD = result.barIDD

          this.usernamePrintOnBill = result.usernamePrintOnBill

          //                this.flex
        })
      },
    },
  }
</script>

<style scoped>

</style>
