<template>
  <v-container>
    <v-btn
      min-height="48"
      min-width="40"
      text
      @click="printPage"
    >
      <v-icon
        size="20"
      >
        mdi-printer
      </v-icon>
      <span
        class="hidden-sm-and-down"
      >
          Print to PDF
      </span>
    </v-btn>
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1300"
        filename="service-number-report"
        :manual-pagination="true"
        pdf-content-width="800px"
        :htmlToPdfOptions="htmlToPdfOptions"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
      >
        <service-number-container-print
          slot="pdf-content"
          :sn-data="serviceNumber"
        />
      </vue-html2pdf>
  </v-container>
</template>

<script>
  import VueHtml2pdf from 'vue-html2pdf'
  import ServiceNumberContainerPrint from '@/views/blitz/components/ServiceNumberPrintContent'
  // import SampleContentPrint from '@/views/blitz/components/SampleContentPrint'
  // import ServiceNumberDetailPrint from '@/views/blitz/components/ServiceNumberDetailPrint'
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters } = createNamespacedHelpers('app')

  export default {
    name: 'ServiceNumberPrint',
    components: {
      VueHtml2pdf,
      ServiceNumberContainerPrint,
    },
    props: {
      snData: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters(['customerInfo']),
      serviceNumber () {
        return this.snDataLocal
      },
    },
    data () {
      return {
        htmlToPdfOptions: {
          margin: 0,
          filename: 'service-number-report.pdf',
          image: {
            type: 'jpeg',
            quality: 0.98
          },
          enableLinks: false,
          html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: 2,
            useCORS: true
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
          }
        },
        snDataLocal: null,
        print: false,
      }
    },
    watch: {
      snData: {
        handler () {
          console.log('ServiceNumberPrint snData changed to ' + this.snData)
          this.snDataLocal = this.snData
        },
      },
    },

    methods: {
      onProgress () {
        console.log('ServiceNumberPrint onProgress')
      },
      hasStartedGeneration () {
        console.log('ServiceNumberPrint hasStartedGeneration')
      },
      hasDownloaded () {
        this.$store.dispatch('app/setProgress', false) // dispatch to the setProgress action in the store
        console.log('ServiceNumberPrint hasDownloaded')
      },
      async beforeDownload ({ html2pdf, options, pdfContent }) {
        console.log('beforeDownload called with options ', options)
        // const newOptions = {...options, ...{scrollX: 0, scrollY: 0}}
        // await html2pdf().set(newOptions).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            // pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            pdf.text(this.customerInfo.customerName + ' Service Number Report page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.48), (pdf.internal.pageSize.getHeight() - 0.3))
            // pdf.text('micandi Customer A', (pdf.internal.pageSize.getWidth() * 0.88), (0.3))
          }
        }).save()
        this.$store.dispatch('app/setProgress', false) // dispatch to the setProgress action in the store
      },
      printPage () {
        this.$store.dispatch('app/setProgress', true) // dispatch to the setProgress action in the store
        this.$gtag.event('serviceNumberPrint', {
          event_category: 'printPdf',
          // event_label: code.dataType,
        })

        this.$refs.html2Pdf.generatePdf()
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
